@import "../../settings";

.servicePage {
  margin: -0px -40px;

  .services-main-container {
    .services-sub-container {
      background-color: $off-white;
      top: 70px;
      width: 100%;
      display: flex;
      position: absolute;
      bottom: 0;

      .services-image {
        background-color: $teal-green;
        background-image: url("../../assets/blissful-services-background-transparent.png");
        background-size: cover;
        background-position: center;
        width: 35%;
        height: 100%;
        border-bottom-right-radius: 20px;
      }

      .services-body-container {
        width: 65%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-bottom: 10px;

        .services-body-text-container {
          color: $blue-gray;
          font-size: 23px;
          font-weight: bold;
          margin: 10% 0 0 15%;
          width: 60%;

          .services-title {
            color: $blue-gray;
            font-size: $large-header-title;
            margin-right: 20px;
            font-style: italic;
          }
        }

        .services-list-container {
          width: 60%;
          margin: 4% 0 0 15%;
          flex: 1;

          .services-item-container {
            margin: 4% 0;

            .services-list-item {
              color: $teal-green;
              display: block;
              font-size: 22px;
              font-weight: bold;
              margin-bottom: 1%;

              .services-checkmark {
                color: $blue-gray;
                margin-left: 5px;
              }
            }

            .services-item-description {
              color: $gray-blue;
              font-size: $medium-text;
            }
          }

          .view-full-list-a-tag {
            background-color: $teal-green;
            border-radius: 10px;
            color: white;
            display: block;
            font-size: $small-text;
            font-weight: bold;
            margin-top: 120px;
            padding: 6px;
            transition: 0.3s;
            text-align: center;
            width: 160px;

            &:hover {
              background-color: $dark-teal-green;
            }
          }
        }
      }
    }
  }

  .additional-services-container {
    margin-top: 100vh;
    padding-top: 10%;
    min-height: 300px;
    width: 100%;
    text-align: center;

    .additional-services-title-container {
      margin: 0% 0 4% 10%;
      display: flex;

      .additional-services-title {
        color: $teal-green;
        font-size: 30px;
        font-family: $lora;
        margin-right: 5%;
        text-align: left;
      }

      .additional-services-title-body {
        color: $gray-blue;
        display: inline-block;
        font-family: $lora;
        font-size: $small-text;
        max-width: 640px;
        vertical-align: middle;
        text-align: left;
      }
    }

    .additional-services-list {
      min-height: 220px;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 6%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 100px;
      row-gap: 50px;
      align-items: start;

      .service-item-box {
        background-color: $beige-gray;
        border-radius: 5px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 20px 25px 0;

        .service-item-body {
          max-height: 140px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;

          .service-item-name {
            color: $teal-green;
            font-size: $small-text;
            font-weight: bold;
            margin-bottom: 15px;
            text-align: left;
          }

          .service-item-text {
            color: $gray-blue;
            text-align: left;
          }

          &:has(+ .service-expand-btn:checked) {
            max-height: none;
            -webkit-line-clamp: 50;
          }
        }

        .service-expand-btn {
          appearance: none;
          font-weight: bold;
          cursor: pointer;
          color: $medium-gray;
          margin: 5% 0 5% 70%;
          padding: 0.5em 0.6em;
          white-space: nowrap;

          &::before {
            content: "Read more";
          }

          &:checked::before {
            content: "Read less";
          }
        }
      }
    }
    .services-contact {
      color: $medium-gray;
      font-size: $xsmall-text;
      margin: 10% 0 3%;

      .services-contact-link {
        color: $medium-gray;
        font-weight: bold;

        &:hover {
          color: $gray-blue;
        }
      }
    }
  }
}

@media only screen and (width: 540px) {
  .services-main-container {
    .services-body-container {
      .services-list-container {
        .services-contact {
          margin-top: 15% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .services-main-container {
    .services-sub-container {
      .services-image {
        display: none;
      }

      .services-body-container {
        width: 100vw !important;

        .services-body-text-container {
          font-size: $small-text !important;
          margin-left: 6% !important;
          width: 90% !important;
        }
      }

      .services-list-container {
        width: 80% !important;
        margin-left: 10% !important;

        .services-item-container {
          .services-list-item {
            font-size: $medium-text !important;
            margin-bottom: 2% !important;

            .services-checkmark {
              display: none;
            }
          }
          .services-item-description {
            font-size: $small-text !important;
          }
        }

        .view-full-list-a-tag {
          text-align: center !important;
          font-size: $small-text !important;
          width: 130px !important;
          padding: 4px !important;
          margin-top: 60px !important;
        }
      }
    }
  }

  .additional-services-container {
    padding-top: 15% !important;

    .additional-services-title-container {
      display: block !important;
      .additional-services-title {
        font-size: 26px !important;
        display: block !important;
        margin-top: 10% !important;
      }
      .additional-services-title-body {
        display: block !important;
        font-size: $small-text !important;
        margin: 3% 0 8%;
        width: 100% !important;
        padding-right: 5%;
      }
    }

    .additional-services-list {
      grid-template-columns: repeat(1, 1fr) !important;

      .service-item-box {
        padding: 17px 20px 0 !important;
        border: 0;

        .service-item-body {
          .service-item-name {
            font-size: $small-text !important;
            margin-bottom: 5px !important;
          }
        }
        .service-expand-btn {
          margin: 3% 0 5% 50% !important;
        }
      }
    }

    .services-contact {
      width: 80%;
      font-size: 14px !important;
      margin-left: 10% !important;
    }
  }
}

@media only screen and (min-width: 679px) and (max-width: 800px) {
  .additional-services-container {
    padding-top: 10% !important;

    .additional-services-list {
      .service-item-box {
        height: 180px;

        .service-item-body {
          -webkit-line-clamp: 15 !important;
        }
      }

      .service-expand-btn {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width: 801px) and (max-width: 1800px) {
  .services-main-container {
    .services-sub-container {
      .services-body-container {
        .services-body-text-container {
          font-size: $small-text !important;
          margin-left: 10% !important;
          width: 80% !important;
        }
      }

      .services-list-container {
        width: 80% !important;
        margin-left: 10% !important;

        .services-item-container {
          .services-list-item {
            font-size: $medium-text !important;
            margin-bottom: 2% !important;
          }
          .services-item-description {
            font-size: $small-text !important;
          }
        }

        .view-full-list-a-tag {
          text-align: center !important;
          font-size: $small-text !important;
          width: 130px !important;
          padding: 4px !important;
          margin-top: 35px !important;
        }
      }
    }
  }

  .additional-services-container {
    padding-top: 7% !important;

    .additional-services-title-container {
      display: block !important;
      .additional-services-title {
        font-size: 26px !important;
        display: block !important;
        margin-top: 10% !important;
      }
      .additional-services-title-body {
        display: block !important;
        font-size: $small-text !important;
        margin: 2% 0 4% !important;
        width: 100% !important;
        padding-right: 5%;
      }
    }

    .additional-services-list {
      grid-template-columns: repeat(2, 1fr) !important;
      column-gap: 50px !important;

      .service-item-box {
        padding: 15px 20px 0 !important;
        .service-item-body {
          .service-item-name {
            font-size: $small-text !important;
            margin-bottom: 10px !important;
          }
        }
        .service-expand-btn {
          margin: 3% 0 3% 70% !important;
        }
      }
    }

    .services-contact {
      width: 90%;
      margin-left: 5% !important;
    }
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1800px) {
  .services-main-container {
    .services-sub-container {
      .services-body-container {
        .view-full-list-a-tag {
          margin-top: 60px !important;
        }
      }
    }
  }

  .additional-services-container {
    padding-top: 5% !important;

    .additional-services-title-container {
      .additional-services-title {
        margin-top: 6% !important;
      }
    }

    .additional-services-list {
      grid-template-columns: repeat(3, 1fr) !important;
      margin-bottom: 6% !important;
    }
  }
}

@media only screen and (min-width: 1801px) and (max-width: 2200px) {
  .services-main-container {
    .services-sub-container {
      .services-body-container {
        .services-body-text-container {
          width: 70% !important;
          font-size: $medium-text !important;
        }

        .services-list-container {
          width: 70% !important;
          margin-top: 2% !important;

          .services-list-item {
            font-size: $medium-text !important;
          }

          .services-item-description {
            font-size: $small-text !important;
          }

          .view-full-list-a-tag {
            margin-top: 80px !important;
          }
        }
      }
    }
  }

  .additional-services-container {
    .additional-services-list {
      column-gap: 70px !important;

      .service-item-box {
        padding: 15px 20px 0 !important;
        .service-item-body {
          .service-item-name {
            font-size: $small-text !important;
            margin-bottom: 10px !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 801px) {
  .service-item-body {
    -webkit-line-clamp: 5;
    &:has(+ .service-expand-btn:checked) {
      max-height: none;
      -webkit-line-clamp: 50;
    }
  }
}
