.hidden {
  opacity: 0;
  transition: all 1s;
  filter: blur(5px);
  transform: translateX(-100%);
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.with-delay {
  transition-delay: 100ms;
}

.service-item-box:nth-child(4n + 1) {
  transition-delay: 200ms;
}
.service-item-box:nth-child(4n + 2) {
  transition-delay: 400ms;
}
.service-item-box:nth-child(4n + 3) {
  transition-delay: 600ms;
}
.service-item-box:nth-child(4n + 4) {
  transition-delay: 800ms;
}

@media only screen and (max-width: 800px) {
  .service-item-box:nth-child(1n + 1) {
    transition-delay: 200ms;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1150px) {
  .service-item-box:nth-child(1n + 1) {
    transition-delay: 200ms;
  }
  .service-item-box:nth-child(2n + 2) {
    transition-delay: 400ms;
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1800px) {
  .service-item-box:nth-child(1n + 1) {
    transition-delay: 200ms;
  }
  .service-item-box:nth-child(2n + 2) {
    transition-delay: 400ms;
  }
  .service-item-box:nth-child(3n + 3) {
    transition-delay: 600ms;
  }
}
