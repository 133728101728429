@import "../../settings";

.getStarted-container {
  background-color: $off-white;
  width: 100%;
  margin: -0px -40px;
  position: absolute;
  top: 70px;
  bottom: 0;
  display: flex;

  .img-container {
    background-image: url("../../assets/blissful-services-background-transparent.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
    height: 100%;
  }

  .getStarted-text-container {
    vertical-align: top;
    width: 50%;
    height: 50%;

    .getStarted-text-sub-container {
      margin: 15% 0 0 20%;
      max-width: 400px;

      .schedule-app-title {
        font-family: $lora;
        font-size: $large-header-title;
        margin-bottom: 15%;
      }

      .getStarted-services-container {
        margin-bottom: 12%;

        .getStarted-services-list {
          font-size: $medium-text;
        }

        .schedule-app-text {
          color: $medium-gray;
          line-height: 20px;
          margin-top: 10px;
          margin-bottom: 30px;
        }

        .register-link {
          display: inline-block;
          font-size: $small-text;
          font-weight: bolder;
          color: $off-white;
          background-color: $teal-green;
          border-radius: 10px;
          padding: 7px 22px;
          margin-bottom: 4%;
          transition: 0.3s;

          &:hover {
            background-color: $dark-teal-green;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .getStarted-container {
    top: 78px;
    display: block;
    height: 1050px;

    .img-container {
      width: 100vw;
      height: 400px;
    }

    .getStarted-text-container {
      display: block;
      border: none;
      width: 100%;

      .getStarted-text-sub-container {
        width: 80%;
        margin: auto;
        margin-top: 20%;

        .schedule-app-title {
          font-size: $medium-text;
          margin-bottom: 35px;
          text-align: center;
          text-underline-offset: 6px;
        }

        .getStarted-services-container {
          .getStarted-services-list {
            font-size: $small-text;
          }
        }

        .register-link {
          text-align: center;
          display: block;
          margin-left: 0;
          width: 100%;
          font-size: 16px !important;
          padding: 6px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .getStarted-container {
    .getStarted-text-container {
      .getStarted-text-sub-container {
        margin: 10% 0 0 15%;
        padding-right: 20px;

        .schedule-app-title {
          font-size: $medium-text;
          margin-bottom: 10%;
        }

        .getStarted-services-container {
          margin-bottom: 8%;

          .getStarted-services-list {
            font-size: $small-text;
          }

          .schedule-app-text {
            margin-bottom: 20px;
          }

          .register-link {
            display: block;
            font-size: 16px;
            max-width: 195px;
            padding: 6px 18px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) and (min-height: 900px) and (max-width: 1023px) {
  .getStarted-text-sub-container {
    margin-top: 40% !important;
  }
}

@media only screen and (width: 1024px) and (min-height: 1200px) {
  .getStarted-container {
    .getStarted-text-container {
      .getStarted-text-sub-container {
        margin: 40% 0 0 18%;

        .schedule-app-title {
          font-size: $large-header-title;
          margin-bottom: 20%;
        }

        .getStarted-services-container {
          margin-bottom: 12%;

          .getStarted-services-list {
            font-size: $medium-text;
          }

          .schedule-app-text {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1800px) {
  .getStarted-container {
    .getStarted-text-container {
      .getStarted-text-sub-container {
        margin-top: 17%;

        .schedule-app-title {
          margin-bottom: 20%;
        }

        .getStarted-services-container {
          .schedule-app-text {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
