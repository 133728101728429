@import "../../settings";

.accepted-insurances {
  height: 300px;
  margin-top: 13vh;
  text-align: center;

  .accepted-insurance-text-container {
    margin-bottom: 10px;
    padding: 0 10%;

    .accepted-insurance-title {
      color: $medium-gray;
      font-size: 34px;
      margin-bottom: 20px;
    }

    .accepted-insurance-text {
      font-size: $small-text;
      color: $medium-gray;

      .accepted-insurance-contact-link {
        color: $medium-gray;
        font-weight: bold;

        &:hover {
          color: $gray-blue;
        }
      }
    }
  }

  .insurance1 {
    height: 65px;
    width: 210px;
    margin: 1%;
    filter: grayscale(100%) brightness(100%) sepia(15%);
  }

  .insurance2 {
    height: 80px;
    width: 210px;
    margin: 1%;
    filter: grayscale(100%) brightness(100%) sepia(15%);
  }

  .insurance3 {
    height: 35px;
    width: 210px;
    margin: 1%;
    filter: grayscale(100%) brightness(100%) sepia(15%);
  }

  .insurance4 {
    height: 40px;
    width: 210px;
    margin: 1%;
    filter: grayscale(100%) brightness(100%) sepia(15%);
  }

  .insurance-logo:nth-child(2) {
    transition-delay: 200ms;
  }
  .insurance-logo:nth-child(3) {
    transition-delay: 400ms;
  }
  .insurance-logo:nth-child(4) {
    transition-delay: 600ms;
  }
  .insurance-logo:nth-child(5) {
    transition-delay: 800ms;
  }
  .insurance-logo:nth-child(6) {
    transition-delay: 1000ms;
  }
  .insurance-logo:nth-child(7) {
    transition-delay: 1200ms;
  }
}

@media only screen and (max-width: 320px) {
  .accepted-insurances {
    .accepted-insurance-title {
      font-size: 20px !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .accepted-insurances {
    min-height: 380px;
    margin-top: 8vh;

    .accepted-insurance-text-container {
      padding: 0 25px;

      .accepted-insurance-title {
        font-size: $medium-text !important;
        margin-bottom: 10px;
      }
      .accepted-insurance-text {
        font-size: 15px;
        margin-bottom: 20px;
      }
    }

    .insurance1 {
      height: 35px;
      width: 120px;
      margin: 3%;
    }

    .insurance2 {
      height: 45px;
      width: 140px;
      margin: 2%;
    }

    .insurance3 {
      height: 15px;
      width: 100px;
      margin: 3%;
      margin-bottom: 20px;
    }

    .insurance4 {
      height: 35px;
      width: 150px;
      margin: 3%;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .accepted-insurances {
    margin-top: 9vh;

    .accepted-insurance-text-container {
      .accepted-insurance-title {
        font-size: 22px;
        margin-bottom: 10px;
      }
      .accepted-insurance-text {
        font-size: $small-text;
        margin-bottom: 25px;
      }
    }

    .insurance1 {
      height: 45px;
      width: 150px;
      margin: 2%;
    }

    .insurance2 {
      height: 65px;
      width: 200px;
      margin: 2%;
    }

    .insurance3 {
      height: 20px;
      width: 120px;
      margin-top: 2%;
      margin-bottom: 30px;
    }

    .insurance4 {
      height: 20px;
      width: 120px;
      margin-top: 2%;
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1800px) {
  .accepted-insurances {
    margin-top: 9vh;

    .accepted-insurance-text-container {
      .accepted-insurance-title {
        font-size: 24px;
        margin-bottom: 10px;
      }
      .accepted-insurance-text {
        font-size: $small-text;
        margin-bottom: 25px;
      }
    }

    .insurance1 {
      height: 55px;
      width: 180px;
      margin: 2%;
    }

    .insurance2 {
      height: 85px;
      width: 200px;
      margin: 2%;
    }

    .insurance3 {
      height: 30px;
      width: 160px;
      margin-top: 2%;
      margin-bottom: 30px;
    }
  }
}
