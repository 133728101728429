@import "../../settings";

.navigation {
  background-color: white;
  display: flex;
  justify-content: space-between;
  height: 80px;
  margin: -0px -40px;
  padding: 0% 5%;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1;

  .logo-container {
    height: 70px;
    width: 400px;
    margin: 4px 0 0 10%;

    .logo-img {
      height: 100%;
      width: 100%;
    }
  }

  .toggle-button {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;

    .bar {
      height: 3px;
      width: 100%;
      background-color: $gray-blue;
      border-radius: 10px;
    }
  }

  .nav-links-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    margin-right: 10%;
    width: 70%;

    .sub-nav-links-container {
      position: relative;

      .sub-nav-link {
        align-items: center;
        background-color: white;
        border-radius: 5px;
        border: 0.5px solid $light-gray;
        color: $medium-gray;
        display: none;
        font-weight: bold;
        height: 30px;
        left: -6px;
        margin-top: 8px;
        padding: 4px;
        position: absolute;
        text-align: center;
        top: 20px;
        width: 100px;
        transition: 0.2s;

        &:hover {
          color: $teal-green;
        }
      }

      &:hover {
        .sub-nav-link {
          display: block;
        }
      }
    }

    .nav-link {
      color: $gray-blue;
      font-weight: bold;
      padding: 10px 15px;
      cursor: pointer;
      position: relative;
      transition: 0.2s;

      &:hover {
        color: $teal-green;
      }
    }

    .book-appointment-link {
      border: 2px solid $teal-green;
      border-radius: 25px;
      color: $teal-green;
      cursor: pointer;
      margin-left: 10px;
      padding: 9px 15px;
      transition: 0.2s;

      &:hover {
        background-color: $teal-green;
        color: $off-white;
      }
    }
  }
}

@media only screen and (max-width: 819px) {
  .navigation {
    padding: 0;
    width: 100%;
    flex-direction: column;

    .logo-container {
      height: 50px;
      width: 200px;
      margin: 15px 0 0 3%;
    }

    .toggle-button {
      margin: 3px 3px 0 0;
      display: flex;
    }

    .nav-link {
      display: none;
    }

    .book-appointment-link {
      display: none;
    }

    .nav-links-container#hidden {
      background-color: $off-white;
      border-bottom: 1px solid $light-gray;
      flex-direction: column;
      min-height: 260px;
      padding: 20px;
      position: relative;
      top: 13px;
      width: 100vw;
      z-index: 2;

      .sub-nav-links-container {
        .sub-nav-link {
          display: none;
        }
      }

      .nav-link {
        display: flex;
        font-size: 15px;
        margin-bottom: 10px;
      }

      .book-appointment-link {
        display: flex;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 550px) and (max-width: 819px) {
  .navigation {
    .logo-container {
      width: 230px !important;
      margin-top: 2% !important;
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
  .navigation {
    .logo-container {
      height: 50px !important;
      width: 260px;
      margin: 15px 0 0 0%;
    }

    .nav-links-container {
      margin-right: 0;

      .nav-link {
        font-size: 14px;
        padding: 10px 10px;
      }

      .book-appointment-link {
        font-size: 13px;
      }
    }
  }
}

@media only screen and (min-width: 1101px) and (max-width: 2000px) {
  .logo-container {
    height: 60px !important;
    width: 330px !important;
    margin: 10px 0 0 2% !important;
  }

  .nav-links-container {
    margin-right: 0 !important;
  }
}
