@import "../../settings";

.contact-message-container {
  .contact {
    .form-label {
      display: block;
      color: black;

      .error {
        font-size: 14px;
      }
    }

    .form {
      background: none;
      color: $medium-gray;
      font-size: $xsmall-text;
      padding: 10px;
      display: block;
      width: 43%;
      border: none;
      border-bottom: 1px solid rgb(207, 203, 203);
      margin: 25px 0;

      &:focus {
        outline: none;
      }
    }

    .message-form {
      background-color: $off-white;
      font-size: $xsmall-text;
      width: 43%;
      height: 80px;
      padding: 12px 20px;
      margin: 6px 0 10px 0;
      display: inline-block;
      border: 1px solid $light-gray;
      border-radius: 4px;
      box-sizing: border-box;

      &:focus {
        background-color: $off-white;
        outline: none;
      }
    }

    .error {
      color: red;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact-message-container {
    .contact {
      .form {
        font-size: smaller;
        width: 100%;
        margin: 20px 0;
      }

      .message-form {
        font-size: 13px;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1800px) {
  .contact-message-container {
    .contact {
      .form {
        font-size: 15px;
        width: 80%;
        margin: 20px 0;
      }

      .message-form {
        font-size: 15px;
        width: 80%;
      }
    }
  }
}
