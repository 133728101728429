@import "../../settings.scss";

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  align-items: center;
  background-color: $gray-blue;
  border-radius: 10px;
  color: $off-white;
  display: flex;
  justify-content: center;
  min-height: 15vh;
  min-width: 17vw;
  padding: 15px 25px;
  outline: none;
  text-align: center;
  font-size: 14px;
}

.modal-button {
  background-color: $gray-blue;
  color: $off-white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  height: 30px;
  margin-top: 30px;
  width: 70px;
}

@media only screen and (min-width: 600px) {
  .modal {
    font-size: $xsmall-text;
    padding: 13px;
  }

  .modal-button {
    font-size: $xsmall-text;
  }
}
