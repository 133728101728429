// Colors
$dark-olive-green: #508c59;
$teal-green: #00745f;
$dark-teal-green: #004b3c;
$blue-gray: #36454f;
$beige-gray: #f8f6f0;
$off-white: #f7f7f7;
$medium-gray: #666666;
$gray-blue: #2c3539;
$light-gray: #a19f9f;
$dark-gray: #424242;
$medium-blue: #3484bd;
$dark-blue: #1c4765;

// Font Size
$large-header-title: 36px;
$medium-text: 20px;
$small-text: 18px;
$xsmall-text: 16px;

// Font Family
$lora: "Lora";
$open-sans: "Open Sans";

html {
  scrollbar-width: normal;
  scrollbar-color: rgb(197, 197, 197) #ffffff;
}

/* Chrome, Edge, and Safari */
html::-webkit-scrollbar {
  width: 16px;
}

html::-webkit-scrollbar-track {
  background: #ffffff;
}

html::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
