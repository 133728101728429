@import "../../settings";

.footer-container {
  background-color: $gray-blue;
  min-height: 250px;
  margin-bottom: -20px;
  padding: 70px 20px 30px 20px;
  max-width: 100vw;
  text-align: center;
  margin-top: 10vh;

  .footer-img-container {
    display: inline-block;
    height: 80px;
    margin-right: 10%;
    width: 320px;
    vertical-align: top;

    .footer-img {
      height: 100%;
      width: 100%;
    }
  }

  .footer-about-container {
    display: inline-block;
    margin: 0 4% 0 4%;
    text-align: left;
    width: 300px;
    vertical-align: top;

    .footer-about-header {
      color: $off-white;
      font-size: $small-text;
      margin-bottom: 0.75em;
    }

    .footer-resources-link {
      color: rgb(255, 255, 255, 0.7);
      display: block;
      font-size: $xsmall-text;
      font-weight: 900;
      margin-bottom: 4%;

      &:hover {
        color: $off-white;
      }
    }
  }

  .footer-contact-container {
    display: inline-block;
    text-align: left;
    width: 300px;

    .footer-contact-header {
      color: $off-white;
      font-size: $small-text;
      margin-bottom: 0.75em;
    }

    .footer-contact-info {
      color: rgb(255, 255, 255, 0.8);
      display: block;
      font-size: $xsmall-text;
      margin-bottom: 0.5em;
    }
  }

  .copyright {
    color: rgb(255, 255, 255, 0.6);
    margin-top: 3%;
    font-size: 14px;
  }
}

.home-page {
  margin-top: 9vh;
}

@media only screen and (max-width: 812px) {
  .home-page {
    margin-top: 3vh;
  }

  .footer-container {
    padding-top: 30px;

    .footer-img-container {
      height: 60px;
      margin-right: 0;
      margin-bottom: 40px;
      width: 240px;
    }

    .footer-about-container {
      margin-bottom: 1.5em;
      width: 80%;

      .footer-about-header {
        font-size: $xsmall-text;
        margin-bottom: 0.5em;
      }

      .footer-member-link {
        font-size: 14px;
      }

      .footer-resources-link {
        margin-bottom: 2%;
      }
    }

    .footer-contact-container {
      margin-bottom: 1.5em;
      width: 80%;

      .footer-contact-header {
        font-size: $xsmall-text;
      }

      .footer-contact-info {
        font-size: 14px;
      }
    }

    .copyright {
      font-size: 12px;
    }
  }
}

@media only screen and (min-width: 813px) and (max-width: 1114px) {
  .home-page {
    margin-top: 5vh;
  }

  .footer-container {
    .footer-img-container {
      height: 60px;
      margin-right: 8%;
      margin-bottom: 40px;
      width: 220px;
    }

    .footer-about-container {
      margin: 0 2% 1.5em 2%;
      width: 180px;

      .footer-about-header {
        font-size: 18px;
        margin-bottom: 0.5em;
      }

      .footer-member-link {
        font-size: 16px;
      }
    }

    .footer-contact-container {
      width: 280px;
      margin-bottom: 1.5em;

      .footer-contact-header {
        font-size: 18px;
      }

      .footer-contact-info {
        font-size: 16px;
      }
    }
  }
}
