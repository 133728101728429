@import "../../settings";

.contact-container {
  min-height: 700px;
  margin: 70px -40px 0;
  width: auto;
  background-color: $off-white;

  .banner-container {
    max-width: 100%;
    height: 310px;

    .banner-img {
      background-image: url("../../assets/blissful-contact-banner.jpg");
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 100%;
    }

    .contact-title {
      color: $teal-green;
      font-size: $large-header-title;
      font-family: $lora;
      margin-top: 1%;
      margin-left: 10%;
    }
  }

  .contact-info-container {
    height: 60vh;
    width: 50%;
    display: inline-block;
    vertical-align: top;

    .contact-info {
      margin-left: 45%;
      margin-top: 15%;

      h4 {
        color: $light-gray;
        margin-bottom: 10px;
      }

      h2 {
        margin-bottom: 30px;
        color: $teal-green;
      }

      .contact-info-text {
        font-size: $small-text;
        display: flex;
        align-items: baseline;
        padding: 6px 0;
        max-width: 400px;

        .contact-info-icons {
          font-size: 25px;
          margin-right: 10px;
        }

        .contact-location-icon {
          margin: auto;
          height: 23px;
          width: 23px;
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }
  }

  .contact-messaging-container {
    display: inline-block;
    height: 700px;
    width: 50%;

    .messaging-container {
      margin-top: 15%;

      h4 {
        color: $light-gray;
        margin-bottom: 10px;
      }

      h2 {
        margin-bottom: 30px;
        color: $teal-green;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact-container {
    .banner-container {
      height: 200px;

      .banner-img {
        height: 125px;
      }

      .contact-title {
        font-size: $medium-text;
        margin-top: 10px;
      }
    }

    .contact-info-container {
      max-height: 200px;
      width: 80%;
      display: block;
      margin-left: 10%;

      .contact-info {
        margin: 0;

        h4 {
          font-size: smaller;
          margin-bottom: 5px;
        }

        h2 {
          font-size: 16px;
          margin-bottom: 15px;
        }

        .contact-info-text {
          font-size: 15px;
          padding: 3px;

          .contact-info-icons {
            font-size: $medium-text;
          }

          .contact-location-icon {
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    .contact-messaging-container {
      height: 500px;
      width: 80%;
      margin-left: 10%;

      .messaging-container {
        h4 {
          font-size: smaller;
          margin-bottom: 5px;
        }

        h2 {
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 2000px) {
  .contact-container {
    .banner-container {
      height: 200px;

      .banner-img {
        height: 100%;
      }

      .contact-title {
        font-size: 30px;
      }
    }

    .contact-info-container {
      .contact-info {
        margin-left: 20%;
        margin-top: 22%;

        h4 {
          margin-bottom: 5px;
        }

        h2 {
          font-size: 20px;
          margin-bottom: 15px;
        }

        .contact-info-text {
          font-size: 16px;
          padding: 3px;
          width: 90%;

          .contact-info-icons {
            font-size: 23px !important;
          }
        }
      }
    }

    .contact-messaging-container {
      .messaging-container {
        margin-top: 22%;

        h4 {
          margin-bottom: 5px;
        }

        h2 {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
  .contact-info {
    margin-left: 35% !important;
  }
}
