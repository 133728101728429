@import "../../settings";

.submit-message-button {
  background-color: $off-white;
  border: 1px solid $medium-gray;
  border-radius: 25px;
  color: $medium-gray;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
  padding: 14px 20px;
  outline: none;
  width: 43%;
  transition: 0.3s;

  &:hover {
    color: white;
    background-color: $gray-blue;
  }
}

@media only screen and (max-width: 600px) {
  .submit-message-button {
    width: 100%;
    padding: 4px 10px;
    height: 40px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1800px) {
  .submit-message-button {
    width: 80%;
    padding: 10px;
  }
}
