@import "../../settings";

.aboutPage-container {
  min-width: 100%;
  margin: -0px -40px;
  margin-top: 70px;

  .about-container {
    width: 100%;
    display: flex;

    .about-body {
      background-color: $teal-green;
      width: 50%;
      max-height: 600px;
      padding: 2% 6%;

      .about-title {
        color: $off-white;
        font-size: $large-header-title;
        font-family: $lora;
        margin: 2% 0 5% 0;
      }

      .about-text-container {
        .about-body-text {
          display: block;
          color: $off-white;
          font-family: $open-sans;
          font-size: $medium-text;

          .see-services-span {
            display: none;
            color: white;
            font-weight: bold;
          }
        }

        .see-services-button {
          background-color: $teal-green;
          border-radius: 5px;
          border: 2px solid $off-white;
          color: $off-white;
          font-size: 16px;
          font-weight: bold;
          height: 40px;
          margin: 5% 0 0 0;
          min-width: 120px;
          padding: 10px;
          transition: 0.3s;

          &:hover {
            background-color: $off-white;
            color: $teal-green;
          }
        }
      }
      .about-expand-btn {
        display: none;
        appearance: none;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        color: $off-white;
        margin-left: 65%;
        padding: 0.5em 0.6em;
        white-space: nowrap;

        &::before {
          content: "Read more";
        }

        &:checked::before {
          content: "Read less";
        }
      }
    }

    .about-image {
      background-image: url("../../assets/blissful-about-body-image.jpg");
      background-size: cover;
      background-position: center;
      display: flex;
      height: 600px;
      width: 50%;
    }
  }

  .meet-our-members-container {
    margin: 8% 0 5% 6%;

    .meet-our-members-title {
      display: inline-block;
      color: $medium-gray;
      font-size: $large-header-title;
      font-family: $lora;
      margin-right: 5%;
      text-decoration: underline 4px $teal-green;
      text-underline-offset: 10px;
      -webkit-text-decoration-line: underline !important;
      -webkit-text-decoration-color: $teal-green !important;
      -webkit-text-decoration-style: solid !important;
      -webkit-text-decoration-thickness: 4px !important;
    }

    .meet-our-members-body {
      display: inline-block;
      font-family: $lora;
      font-size: $small-text;
      max-width: 640px;
      vertical-align: middle;
    }
  }

  .members-main-container {
    width: 90%;
    margin: 0 0 0 5%;

    .members-container {
      background-color: $beige-gray;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 10px;
      display: inline-block;
      margin: 1%;
      min-height: 400px;
      padding: 2% 6%;
      width: 48%;
      vertical-align: top;

      .member-image-container {
        height: 150px;
        display: flex;
        justify-content: center;
        width: 100%;

        .marvin-headshot {
          background-image: url("../../assets/marvin-headshot.jpeg");
          background-size: cover;
          background-position: center;
          border-radius: 50%;
          border: 2px solid $teal-green;
          height: 100%;
          width: 150px;
          vertical-align: middle;
        }

        .ruby-headshot {
          background-image: url("../../assets/ruby-headshot.jpg");
          background-size: cover;
          background-position: center;
          border-radius: 50%;
          border: 2px solid $teal-green;
          height: 100%;
          width: 150px;
          vertical-align: middle;
        }

        .claris-headshot {
          background-image: url("../../assets/blissful-claris-headshot.jpg");
          background-size: cover;
          background-position: center;
          border-radius: 50%;
          border: 2px solid $teal-green;
          height: 100%;
          width: 150px;
          vertical-align: middle;
        }

        .member-info-container {
          height: 100%;
          margin-left: 50px;

          .member-name {
            color: $gray-blue;
            display: block;
            font-family: $lora;
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 12px;
            margin-top: 20px;
          }

          .member-job-title {
            align-items: baseline;
            color: $gray-blue;
            display: flex;
            font-size: $small-text;
            margin-bottom: 3px;

            .job-title-icon {
              height: 16px;
              margin-right: 7px;
              width: 16px;
            }
          }

          .member-location {
            align-items: baseline;
            color: $gray-blue;
            display: flex;
            font-size: $small-text;
            margin-bottom: 3px;

            .location-icon {
              height: 16px;
              margin-right: 4px;
              width: 16px;
            }
          }

          .member-languages {
            color: $gray-blue;
            display: flex;
            align-items: baseline;
            font-size: $small-text;
            margin-bottom: 20px;

            .languages-icon {
              height: 16px;
              margin-right: 7px;
              width: 16px;
              filter: grayscale(100%) brightness(50%);
            }
          }

          .member-tags {
            width: 100%;

            .tags-icon {
              display: inline-block;
              height: 16px;
              margin-right: 5px;
              width: 16px;
              vertical-align: top;
            }

            .tags-container {
              display: inline-block;
              max-width: 80%;

              .tags {
                border-bottom: 1px solid $medium-gray;
                border-radius: 5px;
                color: $off-white;
                font-weight: bold;
                background-color: rgb(52, 116, 95, 0.6);
                white-space: nowrap;
                font-size: 14px;
                margin: 2px;
                padding: 3px 10px;
              }
            }
          }
        }
      }

      .member-body-container {
        margin-top: 50px;
        padding: 0 15px 15px 15px;
        width: 100%;

        .member-body {
          max-height: 300px;
          overflow: hidden;
          position: relative;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          .member-body-header {
            color: $teal-green;
            font-size: $medium-text;
            font-family: $lora;
            margin-bottom: 12px;
            margin-top: 3%;
          }

          .member-body-text {
            color: $gray-blue;
            display: inline-block;
            font-size: $small-text;
            margin: 0 1.5% 3% 0;
          }

          &:has(+ .expand-btn:checked) {
            max-height: none;
            -webkit-line-clamp: 300;
          }
        }
      }

      .expand-btn {
        appearance: none;
        font-weight: bold;
        cursor: pointer;
        color: $medium-gray;
        margin-left: 85%;
        padding: 0.5em 0.6em;
        white-space: nowrap;

        &::before {
          content: "Read more";
        }

        &:checked::before {
          content: "Read less";
        }
      }
    }

    // .anchor {
    //   margin-top: 200px;
    // }
  }
}

@media only screen and (max-width: 520px) {
  #third-tag {
    display: inline-block !important;
    margin-top: 8px !important;
  }
}

@media only screen and (max-width: 634px) {
  .see-services-span {
    display: inline-block !important;
  }
  .about-expand-btn {
    margin-top: 12px;
    display: inline-block !important;
  }
  .see-services-button {
    display: none !important;
  }
}

@media only screen and (min-width: 635px) and (max-width: 675px) {
  .about-text-container {
    -webkit-line-clamp: 100 !important;
  }
}

@media only screen and (max-width: 800px) {
  .aboutPage-container {
    .about-container {
      .about-body {
        width: 100vw;
        padding: 6% 5%;
        max-height: none;

        .about-title {
          font-size: $large-header-title;
          margin-bottom: 20px;
        }

        .about-text-container {
          max-height: 400px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 12;
          -webkit-box-orient: vertical;

          .about-body-text {
            font-size: $small-text;
          }

          .see-services-button {
            font-size: 14px;
            padding: 8px;
          }

          &:has(+ .about-expand-btn:checked) {
            max-height: none;
            -webkit-line-clamp: 30;
          }
        }
      }

      .about-image {
        display: none;
      }
    }

    .meet-our-members-container {
      margin: 10% 0 10% 6%;
      padding-right: 6%;

      .meet-our-member-title {
        margin-bottom: 70px;
        margin-top: 140px;
        text-underline-offset: 6px;
      }
      .meet-our-members-body {
        display: block;
        font-size: $xsmall-text;
        margin-top: 5%;
      }
    }

    .members-main-container {
      width: 100%;
      margin-left: 0;

      .members-container {
        display: block;
        min-height: 300px;
        margin-top: 40px;
        margin-left: 0;
        width: 100%;
        border-radius: 0;

        .member-image-container {
          min-height: 230px;
          width: 100%;

          .marvin-headshot {
            height: 100px;
            margin-right: 20px;
            margin-top: 20px;
            width: 100px;
          }

          .ruby-headshot {
            height: 100px;
            margin-right: 20px;
            margin-top: 20px;
            width: 100px;
          }

          .claris-headshot {
            height: 100px;
            margin-right: 20px;
            margin-top: 20px;
            width: 100px;
          }

          .member-info-container {
            margin-left: 0;
            width: 70%;

            .member-name {
              font-size: 25px;
              margin-top: 20px;
            }

            .member-job-title {
              font-size: 16px;
              display: flex;
              align-items: baseline;
            }

            .member-location {
              font-size: 16px;
              display: flex;
              align-items: baseline;
            }

            .member-languages {
              font-size: 16px;
              display: flex;
              align-items: baseline;
            }

            .member-tags {
              min-height: 70px;
              width: 180px;
              vertical-align: top;

              .tags {
                font-size: 13px;
                padding: 2px 7px;
              }
            }
          }
        }

        .member-body-container {
          margin-top: 0;
          padding-top: 0.25em;
          padding-bottom: 20px;

          .member-body {
            max-height: 255px;

            .member-body-header {
              font-size: $small-text;
              margin: 7% 0 3% 0;
            }

            .member-body-text {
              display: inline-block;
              font-size: 16px;
              line-height: 1.25;
            }
          }
        }

        .expand-btn {
          margin-top: 10px;
          margin-left: 70%;
          padding: 0.3em 0.4em;
          max-width: 160px;
        }
      }
    }
  }
}

@media only screen and (min-width: 801px) and (max-width: 1150px) {
  .aboutPage-container {
    .about-container {
      .about-body {
        overflow-y: scroll;
        padding-bottom: 5%;
      }

      .about-text-container {
        .about-body-text {
          font-size: $small-text !important;
          margin-bottom: 3%;
        }
      }
    }

    .meet-our-members-container {
      margin: 8% 0 6% 6%;
      padding-right: 6%;

      .meet-our-member-title {
        text-underline-offset: 6px;
      }
      .meet-our-members-body {
        display: block;
        font-size: $small-text;
        margin-top: 3%;
      }
    }

    .members-main-container {
      width: 100%;
      margin-left: 0;

      .members-container {
        display: block;
        width: 80%;
        margin: 0 0 4% 10%;
      }
    }
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1599px) {
  .aboutPage-container {
    .about-container {
      .about-text-container {
        .about-body-text {
          font-size: $small-text !important;
          margin-bottom: 3%;
        }
      }
    }

    .meet-our-members-container {
      margin: 8% 0 6% 6%;
      padding-right: 6%;

      .meet-our-members-title {
        text-underline-offset: 6px;
        margin-bottom: 3% !important;
      }

      .meet-our-members-body {
        font-size: $small-text;
      }
    }

    .members-main-container {
      width: 95%;
      margin-left: 2.5%;

      .members-container {
        width: 45%;
        margin: 0 2.5% 4%;
        padding: 2%;
      }

      .member-image-container {
        height: 150px;
        display: flex;
        justify-content: center;
        width: 100%;

        .marvin-headshot {
          height: 95% !important;
        }

        .ruby-headshot {
          height: 95% !important;
        }

        .claris-headshot {
          height: 95% !important;
        }

        .member-info-container {
          margin-left: 35px !important;

          .member-name {
            font-size: $medium-text !important;
          }

          .member-job-title {
            font-size: 16px !important;
          }

          .member-location {
            font-size: 16px !important;
          }

          .member-languages {
            font-size: 16px !important;
            margin-bottom: 15px !important;
          }

          .tags-container {
            .member-tags {
              font-size: 12px !important;
              padding: 3px 6px !important;
            }
          }
        }
      }

      .member-body-container {
        padding: 0 !important;

        .member-body {
          max-height: 200px;
          overflow: hidden;
          position: relative;

          .member-body-header {
            color: $teal-green;
            font-size: $small-text !important;
            font-family: $lora;
            margin-bottom: 20px;
            margin-top: 3%;
          }

          .member-body-text {
            display: inline-block;
            font-size: 16px !important;
            margin: 0 0 3% 0 !important;
          }
        }
      }
      .expand-btn {
        margin: 20px 0 0 80% !important;
      }
    }
  }
}
