@import "../../settings";

.home-container {
  margin: -0px -40px;

  .home-image-container {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        80%,
        white
      ),
      url("../../assets/blissful-home-background.jpg");
    background-size: cover;
    background-position: center;
    margin-top: 70px;
    width: 100%;
    height: 60vh;
    padding-top: 12%;

    .homePage-body {
      margin-left: 10%;
      padding: 15px;
      text-align: center;
      width: 35%;

      .homePage-body-text {
        color: $blue-gray;
        font-family: $lora;
        font-size: 24px;
      }

      .book-appointment-button {
        border: none;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 5px;
        font-size: 15px;
        font-weight: bold;
        color: $medium-gray;
        height: 35px;
        margin: 30px;
        padding: 0 20px;
        opacity: 0.7;
        transition: 0.2s;

        &:hover {
          border: none;
          background-color: rgba(255, 255, 255, 0.8);
          cursor: pointer;
          opacity: 0.9;
          font-weight: bold;
        }
      }
    }
  }
}

@media only screen and (width: 540px) {
  .homePage-body {
    margin-top: 6% !important;
  }
}

@media only screen and (max-width: 600px) {
  .home-container {
    .home-image-container {
      .homePage-body {
        background-color: $off-white;
        border-radius: 5px;
        border: 1px solid $teal-green;
        margin-top: 65px;
        margin-left: 10%;
        padding: 12px 12px 0;
        width: 80%;

        .homePage-body-text {
          font-size: $xsmall-text;
          margin-bottom: 10%;
        }

        .book-appointment-button {
          font-size: 14px;
          margin: 0 0 5% 0;
        }
      }
    }
  }
}

@media only screen and (width: 1024px) and (height: 600px) {
  .home-container {
    .home-image-container {
      .homePage-body {
        margin-top: -3% !important;
        margin-left: 5% !important;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .home-container {
    .home-image-container {
      .homePage-body {
        margin-top: 9%;
        margin-left: 8%;
        width: 50%;

        .homePage-body-text {
          font-size: $medium-text;
        }
      }
    }
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1800px) {
  .home-container {
    .home-image-container {
      .homePage-body {
        margin-top: 3%;
        margin-left: 5%;
        width: 45%;

        .homePage-body-text {
          font-size: $medium-text;
        }
      }
    }
  }
}
